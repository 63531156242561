import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import firebase from '../firebase';
import { useNavigate } from 'react-router';

export default function PageRestricted() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate(); // Create a navigate function

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      if(!user) {
        console.log('not logged in');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Restricted Page">
            Test
            <div>{isLoggedIn ? <p>User is logged in</p> : <p>User is Logged Out</p>}</div>
        </UXCContentBlock>
  </div>
  );
}