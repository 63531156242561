import * as React from 'react';
import './PageProductDetailUserTarget.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import { Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

export default function PageProductDetailUserTarget() {
  return (
    <div>
    <UXCMarquee primaryText="User Target" subText="The Hyper Focused UX Tool"/>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Zeroing in on User Challenges">
          <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    ...
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Features">
            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={6}>
                        <h3>For Product Owners and Developers</h3>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <WorkIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Work" secondary="Jan 7, 2014" />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <BeachAccessIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Vacation" secondary="July 20, 2014" />
                          </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <h3>For Users</h3>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <WorkIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Work" secondary="Jan 7, 2014" />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <BeachAccessIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Vacation" secondary="July 20, 2014" />
                          </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Subscription Plans">
          <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Free Trial" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Learn About UX Training" />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Standard" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Join a Project" />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Enterprise" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Collaborate" />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}