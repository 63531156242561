import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageUXTraining.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCImgMentorshipBasic from '../Images/UXCCard-MentorPlans-Basic.png';
import UXCImgMentorshipHandsOn from '../Images/UXCCard-MentorPlans-Hands-On.png';
import { Button } from '@mui/material';
import firebase from '../firebase';


export default function PageUXMentorshipPlans() {

    const firestore = firebase.firestore();

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch data from Firestore and set it in the state
        firestore.collection('training-courses').get()
        .then(querySnapshot => {
            const coursesData = [];
            querySnapshot.forEach(doc => {
            const course = doc.data();
            coursesData.push({
                courseId: course.courseId,
                courseName: course.courseName,
            });
            });
            setCourses(coursesData);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);


  return (
    <div>
    <UXCMarquee primaryText="Mentorship Plans" subText="Align with seasoned UX mentors at your pace." />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Mentorship Plan Options">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <UXCCard cardHeading="Basic Mentorship (Ask for Pricing)" cardImage={UXCImgMentorshipBasic} shortDescription="Participate in mentor chats, attend group portfolio sessions to get feedback on your designs and practical tips." buttonTitle="Apply Now" buttonPath="/apply-mentorship/Basic" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <UXCCard cardHeading="Hands-On Coaching (Ask for Pricing)" cardImage={UXCImgMentorshipHandsOn} shortDescription="One 1:1 mentor session per month, mentor chat and portfolio session access." buttonTitle="Apply Now" buttonPath="/apply-mentorship/Hands-On" />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}