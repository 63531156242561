import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageUXLeadership.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardUXLeadershipCOE from '../Images/uxi-site-uxleadership-feature-coe.png';
import UXCCardUXLeadershipLowCode from '../Images/uxi-site-uxleadership-feature-lowcode.png';
import UXCCardUXLeadershipTeam from '../Images/uxi-site-uxleadership-feature-uxteam.png';
import UXCCardUXLeadershipTraining from '../Images/uxi-site-uxleadership-feature-training.png';
import UXCCardNonProfit from '../Images/UXCFeatureImg_NonProfit.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCCard from '../Components/UXCCard/UXCCard';
import { Helmet } from 'react-helmet';

export default function PageUXLeadership() {

  return (
    <div>
    <Helmet>
      <title>UX Integrity's Design Team</title>
      <meta name="description" content="UX Integrity is a seasoned user-centric design team focused on enterprise and government clients" />
      {/* Other meta tags */}
    </Helmet>
    <UXCMarquee primaryText="Tenaciously User Focused Leadership" subText="Design 100% Focused on the User, Through the Entire Development Process."/>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Our Focus Areas">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3} className="uxc-page-uxleadership-min-card-height">
                        <UXCCard cardHeading="Center of Excellence" cardImage={UXCCardUXLeadershipCOE} shortDescription="Center of Excellence design requires a culture shift that requires confidence in a solid governance structure.  By providing this framework, clients can build a more sturdy digital transformation future." />
                    </Grid>
                    <Grid item xs={12} lg={3} className="uxc-page-uxleadership-min-card-height">
                        <UXCCard cardHeading="UX in Low Code" cardImage={UXCCardUXLeadershipLowCode} shortDescription="UX in Low Code is a unique challenge.  The transformational opportunities low code presents also include potential risks for user adoption if there is not a solid strategy in place." />
                    </Grid>
                    <Grid item xs={12} lg={3} className="uxc-page-uxleadership-min-card-height">
                        <UXCCard cardHeading="UX Team Building" cardImage={UXCCardUXLeadershipTeam} shortDescription="UX agency and teams have a specific life and vision to be sturdy and create results.  We have the experience and passion to build design teams that work within organizations." />
                    </Grid>
                    <Grid item xs={12} lg={3} className="uxc-page-uxleadership-min-card-height">
                        <UXCCard cardHeading="UX Training" cardImage={UXCCardUXLeadershipTraining} shortDescription="UX is a passion that we are passing along in the best way we know how through our experience in training.  We have designed real-life focused training programs to elevate design knowledge." />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
      </div>
  );
}