import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageContact.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import firebase from '../firebase';
import { addToTrainingRoster } from '../UXCUtilities/uxcutilities-all';
import { Helmet } from 'react-helmet';
import HubspotForm from 'react-hubspot-form';

export default function PageContact() {

  return (
    <UXCPageTemplateUtilityScreen headingOne="Contact UX Integrity">
      <Helmet>
      <title>Contact UX Integrity</title>
      <meta name="description" content="Reach out to UX Integrity to Discuss Your Project" />
      {/* Other meta tags */}
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
            <HubspotForm
            portalId='44366894'
            formId='60c07592-b1bb-4c57-be55-16e3f3f03d94'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
            />
            </Box>
    </UXCPageTemplateUtilityScreen>
  );
}