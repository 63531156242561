import * as React from 'react';
import './PageUXCommunity.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';

export default function PageUXCommunity() {
  return (
    <div>
    <UXCMarquee primaryText="Join a Community of UX Peers and Mentors" subText="(subtext)" buttonTitle="Explore UX Training" />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Community Options">
        <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <UXCCard cardHeading="Community Member" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Learn About UX Training" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <UXCCard cardHeading="Premium Membership" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Join a Project" />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}