import React, { useState, useEffect } from 'react';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from '../firebase';
import axios from 'axios';

function PageSubscribeCaptureNewCustomer() {

  const [stripeApiKey, setStripeApiKey] = useState('');

useEffect(() => {
  // Make the API request to your Google Cloud Function
  axios.get('https://us-central1-user-continuity-392321.cloudfunctions.net/UCSKeyServer')
    .then((response) => {
      setStripeApiKey(response.data.stripeApiKey);
    })
    .catch((error) => {
      console.error('Error fetching Stripe API key:', error);
    });
}, []);

const handleCreateCustomer = async () => {
  try {
    const response = await axios.post(
      'https://api.stripe.com/v1/customers',
      {
        email: 'customer@example.com', // Replace with the customer's email
      },
      {
        headers: {
          'Authorization': `Bearer ${stripeApiKey}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    // Handle the customer creation response
    console.log('Customer created:', response.data);
  } catch (error) {
    console.error('Error creating customer:', error);
    console.error('Error creating customer:', error.response?.data?.error || error.message);
  }
};

const handlePayment = async () => {
  try {
    // Make a payment request to Stripe with the Stripe API key in the Authorization header
    const response = await axios.post(
      'https://api.stripe.com/v1/subscriptions',
      {
        "customer": "cus_OMFKJNFJRM3FUB",   // Replace with the ID of an existing Stripe Customer
        "items": [
          {
            "price": "price_1NZWoyF8NlpzaBD2NRZkRGq9"   // Replace with the ID of the price you want to subscribe the customer to
          }
        ],
        "default_payment_method": "card_1NZhVnF8NlpzaBD29UScvGNp"  // Replace with the ID of an existing payment method for the customer
      },
      {
        headers: {
          'Authorization': `Bearer ${stripeApiKey}`,
          'Content-Type': 'application/x-www-form-urlencoded', // Or the appropriate content type
        },
      }
    );

    // Handle the payment response
    console.log('Payment successful:', response.data);
  } catch (error) {
    console.error('Error processing payment:', error);
    console.error('Error processing payment:', error.response?.data?.error || error.message);
  }
};

  /*
  callCloudFunction();
  */

  return (
    <div>
      <h2>Subscription with Payment</h2>
      <button onClick={handleCreateCustomer}>Create Customer</button>
      <button onClick={handlePayment}>Make Payment</button>
    </div>
  );
}

export default PageSubscribeCaptureNewCustomer;