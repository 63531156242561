import React, { useState, useEffect } from 'react';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from '../firebase';
import axios from 'axios';
import Button from '@mui/material/Button';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';

function PageProductOnboardStep1() {

  const [stripeApiKey, setStripeApiKey] = useState('');
  const [uxcUserEmail, setUXCUserEmail] = useState('');
  const [uxcCustomerID, setUXCCustomerID] = useState('');

useEffect(() => {
    const uxcUserStub = firebase.auth().currentUser;
  if(uxcUserStub) {
    const uxcUserEmailStub = firebase.auth().currentUser.email;
    setUXCUserEmail(uxcUserEmailStub);
    console.log(uxcUserEmail);
  }
})
  

useEffect(() => {
  // Make the API request to your Google Cloud Function
  axios.get('https://us-central1-user-continuity-392321.cloudfunctions.net/UCSKeyServer')
    .then((response) => {
      setStripeApiKey(response.data.stripeApiKey);
    })
    .catch((error) => {
      console.error('Error fetching Stripe API key:', error);
    });
}, []);

const handlePayment = async () => {
  try {
    // Make a payment request to Stripe with the Stripe API key in the Authorization header
    const response = await axios.post(
      'https://api.stripe.com/v1/subscriptions',
      {
        "customer": "cus_OMFKJNFJRM3FUB",   // Replace with the ID of an existing Stripe Customer
        "items": [
          {
            "price": "price_1NZWoyF8NlpzaBD2NRZkRGq9"   // Replace with the ID of the price you want to subscribe the customer to
          }
        ],
        "default_payment_method": "card_1NZhVnF8NlpzaBD29UScvGNp"  // Replace with the ID of an existing payment method for the customer
      },
      {
        headers: {
          'Authorization': `Bearer ${stripeApiKey}`,
          'Content-Type': 'application/x-www-form-urlencoded', // Or the appropriate content type
        },
      }
    );

    // Handle the payment response
    console.log('Payment successful:', response.data);
  } catch (error) {
    console.error('Error processing payment:', error);
    console.error('Error processing payment:', error.response?.data?.error || error.message);
  }
};

const [isLoggedIn, setIsLoggedIn] = useState(false);

useEffect(() => {
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    setIsLoggedIn(!!user);
  });

  return () => unsubscribe();
}, []);

const fetchData = async () => {
    try {
            const db = firebase.firestore();
            const querySnapshot = await db.collection('user-profile').where('email', '==', uxcUserEmail).get();

            if (!querySnapshot.empty) {
                const data = querySnapshot.docs[0].data();
                //setUXCCustomerID(data.email);
                //setUserData(data);
                console.dir(data);
            } else {
                console.log('No user found with the provided email: ' + uxcUserEmail);
            }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  /*
  callCloudFunction();
  */

  return (
    <UXCPageTemplateUtilityScreen headingOne="Subscribe Now.">
        <h2>Subscription with Payment</h2>
        {isLoggedIn ? 
        <div>
        <div>Signed In</div>
        <Button onClick={fetchData}>Fetch Data</Button>
        </div>
        :
        <div>Signed Out</div>
        }
    </UXCPageTemplateUtilityScreen>    
  );
}

export default PageProductOnboardStep1;

/*
        <button onClick={handlePayment}>Make Payment</button>
*/