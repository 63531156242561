import * as React from 'react';
import './PageProducts.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCImgFeatureUserPulse from '../UXCFeatureImg_UXProdUserPulse.png';
import { Button } from '@mui/material';

export default function PageProducts() {
  return (
    <div>
    <UXCMarquee primaryText="Innovation for Industry Gaps" subText="We Simply Find Problems and Use Products to Solve Them"/>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Our Products">
          <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={3}>
                        <img width="100%" height="auto" src={UXCImgFeatureUserPulse} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <h3 className="uxc-global-utility-text-left uxc-typo-color-white">User Target</h3>
                        <div className="uxc-global-utility-text-left uxc-typo-color-white">User Target, a platform agnostic UX support app is coming in Q1 2024 to assist Product Owners, Business Analysts, UX Professionals and Developers in staying in sync on complex projects.  </div>
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}