import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import './PageLogin.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import firebase from '../firebase';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';

export default function PageLogin() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const navigate = useNavigate(); // Create a navigate function

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      // If login is successful, redirect to a specific route (e.g., "/dashboard")
      navigate('/profile');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((error) => {
        setError(error.message);
      });
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  return (
    <UXCPageTemplateUtilityScreen headingOne="Login">
          <div>
            <h2>Email / Password</h2>
            {error && <p>{error}</p>}
            {isLoggedIn ? 
            <div>Signed In</div>
            :
            <div>
            <form onSubmit={handleLogin}>
              <div>
                <TextField id="outlined-basic" label="Email" variant="outlined" InputLabelProps={{ shrink: true }} type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange} />
              </div>
              <div>
                <TextField id="outlined-basic" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange} />
              </div>
              <div>
                <Button className="uxc-component-button-primary" color="inherit" type="submit" variant="contained">Login</Button>
              </div>  
            </form>
            <div><a href="/registration-flow">Or Join Now</a></div>
            </div>
            }
            <div>{isLoggedIn ? <p className="uxc-debug">User is logged in <button onClick={handleSignOut}>Sign Out</button></p> : <p className="uxc-debug">User is Logged Out</p>}</div>
          </div>
        </UXCPageTemplateUtilityScreen>
  );
}