import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageLowCodeDesign.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardPegaEmphasis from '../Images/uxi-lowcode-card-pegaemphasis.png';
import UXCCardLowCodeDesign from '../Images/uxi-lowcode-card-design.png';
import UXCCardCOE from '../Images/uxi-lowcode-card-coe.png';
import UXCCardForProfit from '../Images/UXCFeatureImg_ForProfit.png';
import UXCCardNonProfit from '../Images/UXCFeatureImg_NonProfit.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCCard from '../Components/UXCCard/UXCCard';
import { Helmet } from 'react-helmet';

export default function PageLowCodeDesign() {

  return (
    <div>
    <Helmet>
      <title>UX Integrity is an Experienced Pega and Low Code UX Team</title>
      <meta name="description" content="UX Integrity are not just UX professionals, but experienced Pega and Low Code Subject Matter Experts" />
      {/* Other meta tags */}
    </Helmet>
    <UXCMarquee primaryText="Transform Your Work" subText="Beyond Just Business Process Flows, User Journeys Tell the Full Story"/>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Leveraging the Strengths of Low Code">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} className="uxc-page-workflow-min-card-height">
                        <UXCCard cardHeading="Pega Emphasis" cardImage={UXCCardPegaEmphasis} shortDescription="UX Integrity has 10 years experience in Pega Low Code implementations, working from teams of 1 to over 100, in both the commercial and federal government space." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-workflow-min-card-height">
                        <UXCCard cardHeading="Design in Low Code" cardImage={UXCCardLowCodeDesign} shortDescription="Designing for Low Code requires experience in both UX and Low Code best practices, recognizing the strengths of the platforms used to leverage the features in the right way.  UX Integrity has experience building strong and compliant design in Low Code in some of the most scrutinizing environments." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-workflow-min-card-height">
                        <UXCCard cardHeading="Low Code COE" cardImage={UXCCardCOE} shortDescription="True Low Code governance starts with a strong Center of Excellence.  UX Integrity has experience building subject matter expert teams at the program office of large digital transformations." />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
       
      </div>
  );
}