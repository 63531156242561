import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageUXTraining.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardNoCannedTrainings from '../Images/UXCCard-NoCannedTrainings.png';
import UXCCardHyperFocused from '../Images/UXCCard-HyperFocused.png';
import UXCCardContinuousMentorship from '../Images/UXCCard-ContinuousMentorship.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCImgMonthlyMentorship from '../Images/UXCFeatureImg_MonthlyMentorship.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import { Button } from '@mui/material';
import firebase from '../firebase';


export default function PageUXTrainingBrowseCourses() {

    const firestore = firebase.firestore();

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch data from Firestore and set it in the state
        firestore.collection('training-courses').get()
        .then(querySnapshot => {
            const coursesData = [];
            querySnapshot.forEach(doc => {
            const course = doc.data();
            coursesData.push({
                courseId: course.courseId,
                courseName: course.courseName,
                courseShortDescription: course.courseShortDescription,
                courseLongDescription: course.courseLongDescription
            });
            });
            setCourses(coursesData);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);


  return (
    <div>
    <UXCMarquee primaryText="See What's in Session" subText="See what sessions are current, configured to the attendees." />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="All Courses">
            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={12}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {courses.map(course => (
                          <ListItem>
                            <Grid container spacing={4}>
                                <Grid item xs={12} lg={2}>
                                        <img width="150px" height="auto" src={UXCImgLiveVirtual} />
                                </Grid>
                                <Grid item xs={12} lg={10}>
                                    <div>
                                        <h3>{course.courseName}</h3>
                                        <div>
                                            <p>
                                                <em>{course.courseShortDescription}</em>
                                            </p>
                                            <p>
                                                {course.courseLongDescription}
                                            </p>
                                            <a href={`/apply-course/${course.courseId}`}><Button variant="outlined">Enroll in this Course</Button></a>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                          </ListItem>
                         ))}
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}