import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import './PageForgotPassword.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import firebase from '../firebase';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';

export default function PageForgotPassword() {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setMessage('Password reset email sent. Please check your inbox.');
      })
      .catch((error) => {
        setMessage(`Error: ${error.message}`);
      });
  };

  return (
    <UXCPageTemplateUtilityScreen headingOne="Forgot Password?">
      <h2>Forgot Password</h2>
      <p>{message}</p>
      <div>
      <TextField id="outlined-basic" label="Email" variant="outlined" InputLabelProps={{ shrink: true }} type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
      <Button className="uxc-component-button-primary" color="inherit" variant="contained" onClick={handleResetPassword}>Reset Password</Button>
      </div>
    </UXCPageTemplateUtilityScreen>
    
  );
}