import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageApplyCourse.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import firebase from '../firebase';
import { addToTrainingRoster } from '../UXCUtilities/uxcutilities-all';

export default function PageApplyCourse() {

  const [submissionForm, setSubmissionForm] = useState([]);

  const { courseId } = useParams();

  console.log('courseId: ' + courseId);
  let courseApplication = courseId;

  const [course, setCourse] = React.useState('');

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

    const [emailData, setEmailData] = useState({
      fromEmail: '',
      subject: '',
      firstName: '',
      lastName: '',
      phone: '',
      text: '',
      course: ''
    });

    const firestore = firebase.firestore();

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch data from Firestore and set it in the state
        firestore.collection('training-courses').get()
        .then(querySnapshot => {
            const coursesData = [];
            querySnapshot.forEach(doc => {
            const course = doc.data();
            coursesData.push({
                courseId: course.courseId,
                courseName: course.courseName
            });
            });
            setCourses(coursesData);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);
  
    const sendEmailTwo = async () => {
      try {
        const response = await axios.post('https://us-central1-user-continuity.cloudfunctions.net/sendRegistrationEmailMod', {
          subject: 'Registration Email Confirmation ' + emailData.firstName + " " + emailData.lastName,
          message: `
    <html>
      <body>
        <p>Confirmed Course Registration for: ${courseApplication}</p>
        <p>First Name: ${emailData.firstName}</p>
        <p>Last Name: ${emailData.lastName}</p>
        <p>Phone: ${emailData.phone}</p>
        <p>Email: ${emailData.fromEmail}</p>
      </body>
    </html>
  `,
          phone: emailData.phone
        });
    
        console.log('Email sent 1:', response.data);
        addToTrainingRoster(emailData.firstName, emailData.lastName, "1");
        setSubmissionForm(true);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmailData({ ...emailData, [name]: value });
    };

    const [courseData, setCourseData] = useState([]);
    const [courseTitle, setCourseTitle] = useState([]);
    const [courseShortDescription, setCourseShortDescription] = useState([]);

    // Define a function to fetch course data
    const fetchCourseData = async (courseId) => {
      const db = firebase.firestore();

      try {
        const querySnapshot = await db
          .collection("training-courses")
          .where("courseId", "==", courseId.toString()) // Convert courseId to string
          .get();

        const newData = [];

        querySnapshot.forEach((doc) => {
          newData.push(doc.data());
          setCourseTitle(doc.data().courseName);
          setCourseShortDescription(doc.data().courseShortDescription);
        });

        setCourseData(newData);
        
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    }

    useEffect(() => {
      fetchCourseData(courseId); // Fetch data when the component mounts
    }, []);

    console.dir(courseData);
    console.log(courseTitle);
    


  return (
    <UXCPageTemplateUtilityScreen headingOne="Course Registration">
      {submissionForm == true ? 
        <div>Submission Complete</div>
      :
      <form>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <h2>Next Steps</h2>
            <div className="uxc-page-apply-instructions">
            Sign up here and we'll coordinate with you joining the class.
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="firstName" label="First Name" variant="outlined" value={emailData.firstName} onChange={handleInputChange} />
          </Grid>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="lastName" label="Last Name" variant="outlined" value={emailData.lastName} onChange={handleInputChange} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="fromEmail" label="Email" variant="outlined" value={emailData.fromEmail} onChange={handleInputChange} />
          </Grid>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="phone" label="Phone" variant="outlined" value={emailData.phone} onChange={handleInputChange} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <div className="uxc-page-apply-course-desc">
            <h2>About this Course: {courseTitle}</h2>
              {courseShortDescription}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <Button variant="outlined" onClick={sendEmailTwo}>Register</Button>
          </Grid>
        </Grid>
      </form>
      }
    </UXCPageTemplateUtilityScreen>
  );
}