import * as React from 'react';
import './PageTraining.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCImgFeatureUserPulse from '../UXCFeatureImg_UXProdUserPulse.png';
import UXCCardTrainingCOE from '../Images/uxi-site-uxleadership-feature-coe.png';
import UXCCardTrainingUX from '../Images/uxi-site-training-feature-ux.png';
import UXCCardTrainingLowCode from '../Images/uxi-site-uxleadership-feature-lowcode.png';
import UXCMarketingTrainingListPegaUXItem from '../Images/Marketing/uxi-site-training-list-pega-ux.png';
import { Button } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function PageTraining() {
  return (
    <div>
    <Helmet>
      <title>UX Integrity Pega and UX Training</title>
      <meta name="description" content="UX Integrity offers comprehensive Pega and UX Training" />
      {/* Other meta tags */}
    </Helmet>
    <UXCMarquee primaryText="Training for What's Next for Your Organization or Career" subText="Our Enterprise Experience Translates into Practical Next Day Skills"/>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Training Areas">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} className="uxc-page-training-min-card-height">
                        <UXCCard cardHeading="Center of Excellence (COE)" cardImage={UXCCardTrainingCOE} shortDescription="Building design that scales is a real challenge.  However, should this challenge be addressed with the right strategy - true digital transformation is possible." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-training-min-card-height">
                        <UXCCard cardHeading="User Experience" cardImage={UXCCardTrainingUX} shortDescription="Our experience in building design teams and design strategy for organizations translates into real-life applicable skill for our UX training attendees." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-training-min-card-height">
                        <UXCCard cardHeading="Low Code" cardImage={UXCCardTrainingLowCode} shortDescription="Our training focus in the low code space is to provide various role types with the proper skills to enhance their role’s objectives at their organization, rather than produce canned off-the-shelf training." />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Current Courses">
            <Box sx={{ flexGrow: 1 }}>
                <div className="uxc-page-training-course-list-item-outer">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} className="uxc-page-training-min-card-height">
                            <img src={UXCMarketingTrainingListPegaUXItem} width="100%" height="auto" />
                        </Grid>
                        <Grid item xs={12} lg={8} className="uxc-page-training-min-card-height">
                            <h3>Pega UX | Legacy to Constellation Roadmap</h3>
                            <p className="uxc-font-body">
                                The Pega UX Legacy to Constellation Roadmap is a key multi-faceted training focused on some key areas of competency, understanding:
                                <ul>
                                    <li>How UX is approached in Pega (legacy and future)</li>
                                    <li>How to build user-centered cases and UI</li>
                                    <li>The architecture differences in legacy vs future Pega</li>
                                    <li>The best practices for building on Pega Constellation</li>
                                    <li>How to extend Pega Constellation UX to maximize versatility</li>
                                </ul>
                            </p>
                            <a href="/pega-ux-training"><Button variant="outlined">View Training Details</Button></a>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </UXCContentBlock>
  </div>
  );
}