import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageForOrganizations.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardProjectEngagement from '../Images/UXCCard-OrgProject-Engagement.png';
import UXCCardInternship from '../Images/UXCCard-OrgProject-Intern.png';
import UXCCardOngoing from '../Images/UXCCard-OrgProject-Ongoing.png';
import UXCCardForProfit from '../Images/UXCFeatureImg_ForProfit.png';
import UXCCardNonProfit from '../Images/UXCFeatureImg_NonProfit.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCCard from '../Components/UXCCard/UXCCard';
import axios from 'axios';
import firebase from '../firebase';
import { addToTrainingRoster } from '../UXCUtilities/uxcutilities-all';

export default function PageForOrganizations() {

  const [submissionForm, setSubmissionForm] = useState([]);

    const [emailData, setEmailData] = useState({
      fromEmail: '',
      subject: '',
      firstName: '',
      lastName: '',
      phone: '',
      text: ''
    });

    const firestore = firebase.firestore();
  
    const sendEmailTwo = async () => {
      try {
        const response = await axios.post('https://us-central1-user-continuity.cloudfunctions.net/sendRegistrationEmailMod', {
          subject: 'Registration Email Confirmation ' + emailData.firstName + " " + emailData.lastName,
          message: `
    <html>
      <body>
        <p>Contact From UX Integrity</p>
        <p>First Name: ${emailData.firstName}</p>
        <p>Last Name: ${emailData.lastName}</p>
        <p>Phone: ${emailData.phone}</p>
        <p>Email: ${emailData.fromEmail}</p>
      </body>
    </html>
  `,
          phone: emailData.phone
        });
    
        console.log('Email sent 1:', response.data);
        addToTrainingRoster(emailData.firstName, emailData.lastName, "1");
        setSubmissionForm(true);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmailData({ ...emailData, [name]: value });
    };

  return (
    <div>
    <UXCMarquee primaryText="Run Innovation Initiatives with Aspiring UX Talent" subText="Up and coming UX strategists and designers are ready to join you."/>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Offerings for Organizations">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} className="uxc-page-organizations-min-card-height">
                        <UXCCard cardHeading="Project Engagements" cardImage={UXCCardProjectEngagement} shortDescription="Our project engagements are designed for you and a team is hand-selected to fit the need." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-organizations-min-card-height">
                        <UXCCard cardHeading="UX Internships" cardImage={UXCCardInternship} shortDescription="Our UX Internship program pairs aspiring UX professionals with individual placements to provide career growth." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-organizations-min-card-height">
                        <UXCCard cardHeading="Ongoing UX Support" cardImage={UXCCardOngoing} shortDescription="UX never stops, so we provide a continuous UX support option to regularly improve your user engagement over time." />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="How it Works">
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={1}>
                        <div className="uxc-screen-step-block">1</div>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <h3 className="uxc-global-utility-text-left">Step 1 - Project Type: Determine Project Type(s) that Suits Your Organization</h3>
                        <div className="uxc-global-utility-text-left">Based on your organization needs decide whether a fixed time frame engagement, ongoing support, or internship suits your team best.</div>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={1}>
                        <div className="uxc-screen-step-block">2</div>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <h3 className="uxc-global-utility-text-left">Step 2 - Sign Up: Register with us and UX Integrity will Broker the UX Work</h3>
                        <div className="uxc-global-utility-text-left">UX Integrity acts as the project manager, coordinating and managing a junior UX team on your behalf.</div>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={1}>
                        <div className="uxc-screen-step-block">3</div>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <h3 className="uxc-global-utility-text-left">Step 3 - Kickoff: A Project Plan will be Presented and Team Members will be Assigned</h3>
                        <div className="uxc-global-utility-text-left">UX Integrity will present a project plan to you, defining milestones and business outcomes.</div>
                    </Grid>
                </Grid>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Get Started and Save on Innovation Today">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                      <Grid item xs={12} lg={3}>
                          <img width="100%" height="auto" src={UXCCardForProfit} />
                      </Grid>
                      <Grid item xs={12} lg={9}>
                          <h3 className="uxc-global-utility-text-left">Small to Large Companies</h3>
                          <div className="uxc-global-utility-text-left">Save cost on UX and become more innovative whether you are a startup or a large company with complex business challenges.</div>
                          <br />
                          <div className="uxc-global-utility-text-left"></div>
                      </Grid>
                  </Grid>
                  <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                      <Grid item xs={12} lg={3}>
                          <img width="100%" height="auto" src={UXCCardNonProfit} />
                      </Grid>
                      <Grid item xs={12} lg={9}>
                          <h3 className="uxc-global-utility-text-left">Non-profit Organizations</h3>
                          <div className="uxc-global-utility-text-left">Our goal is to serve communities locally and globally by leveraging technology and innovation for good.  Non-profit organizations can apply to have UX services for their organization at low or no cost.</div>
                          <br />
                          <div className="uxc-global-utility-text-left"></div>
                      </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {submissionForm == true ? 
                    <div>Submission Complete</div>
                  :
                  <form>
                    <Grid container spacing={2} className="uxc-global-utility-text-left">
                      <Grid item lg={12}>
                        <h2>Let's Discuss Your Project!</h2>
                        <div className="uxc-page-apply-instructions">
                        We want to hear from you, drop us a line and we'll follow up!
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6}>
                        <TextField fullWidth id="outlined-basic" name="firstName" label="First Name" variant="outlined" value={emailData.firstName} onChange={handleInputChange} />
                      </Grid>
                      <Grid item lg={6}>
                        <TextField fullWidth id="outlined-basic" name="lastName" label="Last Name" variant="outlined" value={emailData.lastName} onChange={handleInputChange} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6}>
                        <TextField fullWidth id="outlined-basic" name="fromEmail" label="Email" variant="outlined" value={emailData.fromEmail} onChange={handleInputChange} />
                      </Grid>
                      <Grid item lg={6}>
                        <TextField fullWidth id="outlined-basic" name="phone" label="Phone" variant="outlined" value={emailData.phone} onChange={handleInputChange} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="uxc-global-utility-text-left">
                      <Grid item lg={12}>
                        <Button variant="outlined" onClick={sendEmailTwo}>Send</Button>
                      </Grid>
                    </Grid>
                  </form>
                  }
                </Grid>
              </Grid>
            </Box>
        </UXCContentBlock>
      </div>
  );
}