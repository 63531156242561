import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageEmergingTech.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardIOT from '../Images/uxi-site-emerging-feature-iot.png';
import UXCCardDigitalReality from '../Images/uxi-site-emerging-feature-vr.png';
import UXCCardRobotics from '../Images/uxi-site-emerging-feature-robotics.png';
import UXCCardForProfit from '../Images/UXCFeatureImg_ForProfit.png';
import UXCCardNonProfit from '../Images/UXCFeatureImg_NonProfit.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCCard from '../Components/UXCCard/UXCCard';

export default function PageEmergingTech() {

  return (
    <div>
    <UXCMarquee primaryText="Removing Limitations on User Experience" subText="Realizing the Business Value of Emerging Tech Starts with the User"/>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Emerging Tech Focus Areas">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} className="uxc-page-emergingtech-min-card-height">
                        <UXCCard cardHeading="Internet-of-Things (IoT)" cardImage={UXCCardIOT} shortDescription="The power of IoT is profound, but orchestration is needed to fully realize its true vision. Our goal is to connect the user with the devices that make sense to drive business value." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-emergingtech-min-card-height">
                        <UXCCard cardHeading="Digital Reality" cardImage={UXCCardDigitalReality} shortDescription="Digital Reality prioritization and design needs to start with the user.  Our goal is to prototype what Digital Reality solutions should be, to help our clients take the leap." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-emergingtech-min-card-height">
                        <UXCCard cardHeading="Robotics and Hardware" cardImage={UXCCardRobotics} shortDescription="High Tech hardware is revolutionizing how we interact and do work, but it also needs to be tied to the user and their business systems, a solution we can provide to our clients." />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
      </div>
  );
}