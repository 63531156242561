import React from 'react';
import './UXCPageTemplateUtilityScreen.css';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';

const UXCPageTemplateUtilityScreen = (props) => {

  return (
    <Container maxWidth="md" >
      <div className="uxcpagetemplate-utilityscreen-headingwrap"><h1>{props.headingOne}</h1></div>
      <div className="uxcpagetemplate-utilityscreen-container">
              <Box sx={{ flexGrow: 1 }}>
                  
                  {/* Display any custom content placed within the TestComponent tags */}
                  {props.children}
              </Box>
      </div>
    </Container>
  );
};

export default UXCPageTemplateUtilityScreen;