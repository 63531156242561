import React, { useState } from 'react';
import firebase from '../firebase';

function PageRegister() {
  const [error, setError] = useState(null);
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);

  const handleGoogleRegistration = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // Registration successful
        setIsRegistrationSuccessful(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div>
      <h2>Registration</h2>
      {error && <p>{error}</p>}
      {isRegistrationSuccessful && <p>Registration successful! You can now log in.</p>}
      <button onClick={handleGoogleRegistration}>Register with Google</button>
    </div>
  );
}

export default PageRegister
