import * as React from 'react';
import './PageScheduleTraining.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardNoCannedTrainings from '../Images/UXCCard-NoCannedTrainings.png';
import UXCCardHyperFocused from '../Images/UXCCard-HyperFocused.png';
import UXCCardContinuousMentorship from '../Images/UXCCard-ContinuousMentorship.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCImgMonthlyMentorship from '../Images/UXCFeatureImg_MonthlyMentorship.png';
import { Button } from '@mui/material';
import HubspotForm from 'react-hubspot-form';

export default function PageScheduleTraining() {
  return (
    <div>
    <UXCMarquee primaryText="Sign Up for Training" />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg" headingTwo="Register and We'll Set up a Call to Discuss Pricing and Timing">
        <Box sx={{ flexGrow: 1 }}>
            <HubspotForm
            portalId='44366894'
            formId='acf2dfc4-bd78-46ec-bb45-fd30d446f444'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
            />
            </Box>
        </UXCContentBlock>
  </div>
  );
}