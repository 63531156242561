import React from 'react';
import './UXCContentBlock.css';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';

const UXCContentBlock = (props) => {

  return (
    <div className={"uxc-comp-content-block " + props.width + " " + props.background}>
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1 }} className="uxc-comp-content-block-box">
                <h2>{props.headingTwo}</h2>
                {/* Display any custom content placed within the TestComponent tags */}
                {props.children}
            </Box>
        </Container>
    </div>
  );
};

export default UXCContentBlock;