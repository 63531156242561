import './App.css';
import UXCHeaderNew from './Components/UXCHeaderNew/UXCHeaderNew';
import UXIHeader from './Components/UXIHeader/UXIHeader';
import Box from '@mui/material/Box';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageHome from './Pages/PageHome';
import PageAbout from './Pages/PageAbout';
import PageWorkInUX from './Pages/PageWorkInUX';
import PageUXTraining from './Pages/PageUXTraining';
import PageUXCommunity from './Pages/PageUXCommunity';
import PageProducts from './Pages/PageProducts';
import PageServicesBrokerage from './Pages/PageServicesBrokerage';
import PageOurVision from './Pages/PageOurVision';
import PageLogin from './Pages/PageLogin';
import PageRestricted from './Pages/PageRestricted';
import PageRegister from './Pages/PageRegister';
import PageRegistrationWithPayment from './Pages/PageRegistrationWithPayment';
import PageRegistrationFlowStep1 from './Pages/PageRegistrationFlowStep1';
import PageProfile from './Pages/PageProfile';
import PageForgotPassword from './Pages/PageForgotPassword';
import PageSubscribeCaptureNewCustomer from './Pages/PageSubscribeCaptureNewCustomer';
import PageProductOnboardStep1 from './Pages/PageProductOnboardStep1';
import PageSubscribeLowCode from './Pages/PageSubscribeLowCode';
import PageProductDetailUserTarget from './Pages/PageProductDetailUserTarget';
import PageApplyCourse from './Pages/PageApplyCourse';
import PageUXTrainingBrowseCourses from './Pages/PageUXTrainingBrowseCourses';
import PageUXMentorshipPlans from './Pages/PageUXMentorshipPlans';
import PageApplyMentor from './Pages/PageApplyMentor';
import PageApplyProject from './Pages/PageApplyProject';
import PageContact from './Pages/PageContact';
import PageForOrganizations from './Pages/PageForOrganizations';
import PageLowCodeDesign from './Pages/PageLowCodeDesign';
import PageEmergingTech from './Pages/PageEmergingTech';
import PageUXLeadership from './Pages/PageUXLeadership';
import PageTraining from './Pages/PageTraining';
import PageScheduleTraining from './Pages/PageScheduleTraining';
import PageInnovationEngine from './Pages/PageInnovationEngine';

import PageMarketingTrainingPegaUI from './Marketing/PageMarketingTrainingPegaUI';


function App() {

  return (
    <div className="App">
      <UXCHeaderNew />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/work-in-ux" element={<WorkInUX />}/>
          <Route exact path="/ux-training" element={<UXTraining />}/>
          <Route exact path="/ux-community" element={<UXCommunity />}/>
          <Route exact path="/products" element={<Products />}/>
          <Route exact path="/services-brokerage" element={<ServicesBrokerage />}/>
          <Route exact path="/our-vision" element={<OurVision />}/>
          <Route exact path="/login" element={<Login />}/>
          <Route exact path="/restricted" element={<Restricted />}/>
          <Route exact path="/register" element={<Register />}/>
          <Route exact path="/registration-flow" element={<RegistrationFlowStep1 />}/>
          <Route exact path="/profile" element={<Profile />}/>
          <Route exact path="/forgot-password" element={<ForgotPassword />}/>
          <Route exact path="/subscribe-capture-new-customer" element={<SubscribeCaptureNewCustomer />} />
          <Route exact path="/product-onboarding-step-1" element={<ProductOnboardStep1 />} />
          <Route exact path="/subscribe-low-code" element={<SubscribeLowCode />} />
          <Route exact path="/product-detail-user-target" element={<ProductDetailUserTarget />} />
          <Route exact path="/ux-training-courses" element={<TrainingBrowseCourses />} />
          <Route path="/apply-course/:courseId?" element={<ApplyCourse />} />
          <Route exact path="/ux-mentorship-plans" element={<MentorshipPlans />} />
          <Route path="/apply-mentorship/:mentorPlanId?" element={<ApplyMentorship />} />
          <Route path="/apply-project/:projectRequestId?" element={<ApplyProject />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/for-organizations" element={<ForOrganizations />} />
          <Route path="/low-code-design" element={<LowCodeDesign />} />
          <Route path="/emerging-tech" element={<EmergingTech />} />
          <Route path="/ux-leadership" element={<UXLeadership />} />
          <Route path="/training" element={<Training />} />
          <Route path="/schedule-training" element={<ScheduleTraining />} />
          <Route path="/innovation-engine" element={<InnovationEngine />} />

          <Route path="/pega-ux-training" element={<MarketingTrainingPegaUI/>} />
        </Routes>
    </Router>
    </div>
  );
}

function Home() {
  return (
    <PageHome />
  );
}

function About() {
  return (
    <PageAbout />
  );
}

function WorkInUX() {
  return (
    <PageWorkInUX />
  );
}

function UXTraining() {
  return (
    <PageUXTraining />
  );
}

function UXCommunity() {
  return (
    <PageUXCommunity />
  );
}

function Products() {
  return (
    <PageProducts />
  );
}

function ServicesBrokerage() {
  return (
    <PageServicesBrokerage />
  );
}

function OurVision() {
  return (
    <PageOurVision />
  );
}

function Login() {
  return (
    <PageLogin />
  );
}

function Restricted() {
  return (
    <PageRestricted />
  );
}

function Register() {
  return (
    <PageRegister />
  );
}

function RegistrationWithPayment() {
  return (
    <PageRegistrationWithPayment />
  );
}

function RegistrationFlowStep1() {
  return (
    <PageRegistrationFlowStep1 />
  );
}

function Profile() {
  return (
    <PageProfile />
  );
}

function ForgotPassword() {
  return (
    <PageForgotPassword />
  );
}

function SubscribeCaptureNewCustomer() {
  return (
    <PageSubscribeCaptureNewCustomer />
  );
}

function ProductOnboardStep1() {
  return (
    <PageProductOnboardStep1 />
  );
}

function SubscribeLowCode() {
  return (
    <PageSubscribeLowCode />
  );
}

function ProductDetailUserTarget() {
  return (
    <PageProductDetailUserTarget />
  );
}

function ApplyCourse() {
  return (
    <PageApplyCourse />
  );
}

function TrainingBrowseCourses() {
  return (
    <PageUXTrainingBrowseCourses />
  );
}

function MentorshipPlans() {
  return (
    <PageUXMentorshipPlans />
  );
}

function ApplyMentorship() {
  return (
    <PageApplyMentor />
  );
}

function ApplyProject() {
  return (
    <PageApplyProject />
  );
}

function Contact() {
  return (
    <PageContact />
  );
}

function ForOrganizations() {
  return (
    <PageForOrganizations />
  );
}

function LowCodeDesign() {
  return (
    <PageLowCodeDesign />
  );
}

function EmergingTech() {
  return (
    <PageEmergingTech />
  );
}

function UXLeadership() {
  return (
    <PageUXLeadership />
  );
}

function Training() {
  return (
    <PageTraining />
  );
}

function ScheduleTraining() {
  return (
    <PageScheduleTraining />
  );
}

function InnovationEngine() {
  return (
    <PageInnovationEngine />
  );
}

/* Start Marketing Pages */

function MarketingTrainingPegaUI() {
  return (
    <PageMarketingTrainingPegaUI />
  );
}

export default App;
