import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import UXCPaymentForm from "../Components/UXCPaymentForm/UXCPaymentForm";
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import firebase from '../firebase';
import 'firebase/firestore';
import { useNavigate } from 'react-router';

const cloudFunctionUrl = "https://us-central1-user-continuity.cloudfunctions.net/stripeApi/create-payment-intent";

const stripePromise = loadStripe("pk_test_51NEPSgF8NlpzaBD2UaGRgDQ3wo49GXP9NUztCp9pQX7vfBqGcQgOt79b6z1nhVRUGDO6GzugO9jEWdY02DNvUqch00CjC208vx");


export default function PageSubscribeLowCode() {
  const [userData, setUserData] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // Create a navigate function

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      if (!user) {
        console.log('not logged in');
        navigate('/login');
      } else {
        // Assuming you have a 'users' collection in Firestore and 'email' field
        const db = firebase.firestore();
        console.dir(user);

        db.collection('user-profile')
          .where('email', '==', user.email) // Query by email
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              // Assuming there's only one user with a unique email address
              const userData = querySnapshot.docs[0].data();
              setUserData(userData);
              console.log('found customer:' + userData.stripeCustomerID);
              console.dir(userData);
            } else {
              console.log('No user found with this email address.');
            }
          })
          .catch((error) => {
            console.log('Error querying Firestore:', error);
          });

        // Create PaymentIntent by making a request to the Firebase Cloud Function
        fetch(cloudFunctionUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }), // Adjust this payload as needed
        })
          .then((res) => res.json())
          .then((data) => setClientSecret(data.clientSecret))
          .catch((error) => {
            console.error("Error creating PaymentIntent:", error);
          });
      }
    });

    return () => unsubscribe();
  }, [navigate]); // Added navigate as a dependency for useEffect

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <UXCPageTemplateUtilityScreen headingOne="Subscribe Now">
      <div>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <UXCPaymentForm />
          </Elements>
        )}
      </div>
    </UXCPageTemplateUtilityScreen>
  );
}