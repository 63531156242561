import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './UXCMarquee.css';
import { Button } from '@mui/material';

function UXCMarquee(props) {
  // Component logic and JSX
  const isVisible = props.buttonTitle;

  return (
    <div className={"uxc-comp-marquee-wrapper " + " " + props.background}>
        <Container maxWidth="lg">
            <h1>{props.primaryText}</h1>
            <p>{props.subText}</p>
            
            {isVisible && <a href={props.buttonPath}><Button variant="outlined">{props.buttonTitle}</Button></a>}
        </Container>
    </div>
  );
}

export default UXCMarquee;