import * as React from 'react';
import './PageWorkInUX.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardInternships from '../Images/UXCCard-Internships.png';
import UXCCardWorkCommercial from '../Images/UXCCard-WorkCommercial.png';
import UXCCardWorkNonprofit from '../Images/UXCCard-WorkNonprofit.png';
import { Button } from '@mui/material';

export default function PageWorkInUX() {
  return (
    <div>
    <UXCMarquee primaryText="Jump Right Into a Project" subText="Coordinate with us to get on a Project" buttonTitle="Apply to Work in UX" buttonPath="/apply-project" />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="UX Project Types">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid className="uxc-page-workinux-min-card-height" item xs={12} lg={4}>
                        <UXCCard cardHeading="UX Internships" cardImage={UXCCardInternships} shortDescription="Join a team as an intern (paid and unpaid) to grow your skills while on impactful projects, building your portfolio for the next step." buttonTitle="Apply Now" buttonPath="/apply-project/internship" />
                    </Grid>
                    <Grid className="uxc-page-workinux-min-card-height" item xs={12} lg={4}>
                        <UXCCard cardHeading="UX Junior Commercial" cardImage={UXCCardWorkCommercial} shortDescription="Join a commercial UX project to provide UX and innovation on a project basis (paid).  Our commercial clients are looking to hire Junior UX professionals wanting to build experience." buttonTitle="Apply Now" buttonPath="/apply-project/commercial" />
                    </Grid>
                    <Grid className="uxc-page-workinux-min-card-height" item xs={12} lg={4}>
                        <UXCCard cardHeading="UX Nonprofit" cardImage={UXCCardWorkNonprofit} shortDescription="Join a good cause to provide UX professional services while gaining valuable experience in real projects (unpaid)." buttonTitle="Give Back" buttonPath="/apply-project/nonprofit" />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="How it Works">
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={1}>
                        <div className="uxc-screen-step-block">1</div>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <h3 className="uxc-global-utility-text-left">Step 1: Choose a Project Type(s) that Suits You</h3>
                        <div className="uxc-global-utility-text-left">You can choose how you work with us and what project you'd like to find a fit for.  Choose to be aligned with one or more types to get started.</div>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={1}>
                        <div className="uxc-screen-step-block">2</div>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <h3 className="uxc-global-utility-text-left">Step 2: Apply to Join a Future Project Team</h3>
                        <div className="uxc-global-utility-text-left">Complete an application so we can start working with you.</div>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={1}>
                        <div className="uxc-screen-step-block">3</div>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <h3 className="uxc-global-utility-text-left">Step 3: We Will Coordinate with You to Find Your Next Project with Us.</h3>
                        <div className="uxc-global-utility-text-left">Work alongside us while we connect you with a project that fits your skillsets and interests.</div>
                    </Grid>
                </Grid>
                <div><a href="/apply-project"><Button variant="outlined">Apply to get on a Project</Button></a></div>
        </UXCContentBlock>
  </div>
  );
}