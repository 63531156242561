import * as React from 'react';
import './PageMarketingTrainingPegaUI.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCFeatureLargeVision from '../Images/UXCFeatureLarge-Vision.png';
import UXCFeatureLargeVisionCommunity from '../Images/UXCFeatureLarge-Vision-Community.png';
import UXCCardVisionTraining from '../Images/UXCCard-Vision-Training.png';
import UXCCardVisionMentoring from '../Images/UXCCard-Vision-Mentoring.png';
import UXCCardVisionServantLeadership from '../Images/UXCCard-Vision-ServantLeadership.png';
import UXCFeatureImgPegaUX from '../Images/Marketing/uxi-marketing-pegaui-featureimg.png';
import InstructorProfileImg from '../Images/instructor-profile.jpeg';
import { Button } from '@mui/material';

export default function PageMarketingTrainingPegaUI() {
  return (
    <div>
    <div className='uxc-page-marketing-training-marquee-wrap'>
        <UXCMarquee primaryText="Pega UX Training | Legacy to Constellation Roadmap" subText="Learn how to design effectively in both Legacy and Constellation UX" buttonTitle="Schedule Training" buttonPath="/schedule-training" background="ui-marketing-marquee-bg-pega-ui" />
    </div>
        <Grid container spacing={4}>
            <Grid item xs={12} lg={10}>
                <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Learn How to Scale Future Ready Pega UX">
                    <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <img src={UXCFeatureImgPegaUX} width="100%" height="auto" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <h3 className="uxc-global-utility-text-left">Hands-On from Strategy to Components</h3>
                                <div className="uxc-global-utility-text-left"><p>Regardless of the Pega version(s) your ecosystem is on, understanding low code UX strategy around the use of Pega UI is a critical factor to building usable and scalable applications.  We review both legacy and future versions of Pega, specifically on how to build on both architectures and unify them.</p><p>Attendees will be able to define a UX approach around their Pega apps, define differences in architecture approaches, be able to build their own components, and identify UX best practices.</p></div>
                            </Grid>
                    </Grid>
                </UXCContentBlock>
                <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="3 Modules, 1 Broad 18 hr Enablement Course">
                        <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                            <Grid item xs={12} lg={1}>
                                <div className="uxc-screen-step-block">1</div>
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <h3 className="uxc-global-utility-text-left">Module Set 1: Pega UX Essentials</h3>
                                <div className="uxc-global-utility-text-left">In this module set, we start with general UX best practices.  Next, we parallel the Pega Academy UI Solutions course, putting it in context of both legacy and future Pega UX realities.  Real-world references and a blend of Pega's core building blocks for UX with the day-to-day will provide practical experience.</div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                            <Grid item xs={12} lg={1}>
                                <div className="uxc-screen-step-block">2</div>
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <h3 className="uxc-global-utility-text-left">Module Set 2: The User-Centric Pega Case</h3>
                                <div className="uxc-global-utility-text-left">In this module set, we explore how a Pega Case is designed considering user-centric best practices.  In addition, we dive into the differences between building a Pega case in legacy Pega UI vs Pega Constellation.</div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                            <Grid item xs={12} lg={1}>
                                <div className="uxc-screen-step-block">3</div>
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <h3 className="uxc-global-utility-text-left">Module Set 3: Hands-on Building in Pega Constellation</h3>
                                <div className="uxc-global-utility-text-left">In this module set, we detail in a hands-on environment how the Pega Constellation approach works and how to best build components and case elements with it from end-to-end.</div>
                            </Grid>
                        </Grid>
                        <div><a href="/schedule-training"><Button variant="outlined">Schedule Training Today</Button></a></div>
                </UXCContentBlock>
            </Grid>
            <Grid item xs={12} lg={2} className="uxc-marketing-training-sidepanel-outer">
                <h3>About the Instructor</h3>
                <img src={InstructorProfileImg} width="100%" height="auto" />
                <p>
                    <strong>Tim Joseph<br />Founder | UX Integrity</strong>
                </p>
                <p className="uxc-global-utility-text-left">
                    <div><strong>A Pega UX SME</strong>
                    <br/><em>Pega CSSA, CBA, UI SME</em>
                    </div>
                    <br />
                    <div>
                        Starting his career with Pega UX with Pega directly 8+ years ago, Tim has also run UX teams in various capacities before starting UX Integrity.  He would now like to pass on his experience with this critical subject.
                    </div>
                    <br />
                    <div><strong>Clients Served Examples</strong></div>
                    <ul>
                        <li>MasterCard</li>
                        <li>Sprint</li>
                        <li>JP Morgan & Chase</li>
                        <li>Verizon</li>
                        <li>US Census Bureau</li>
                        <li>John Deere</li>
                        <li>Volvo Trucks</li>
                        <li>Starbucks</li>
                        <li>Food and Drug Administration</li>
                        <li>Internal Revenue Service</li>
                        <li>Social Security Administration</li>
                    </ul>
                </p>
                
            </Grid>
        </Grid>
  </div>
  );
}