import * as React from 'react';
import './PageHome.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCImgFeatureUXExpert from '../UXCFeatureImg_UXExperts.png';
import UXCImgFeatureUXJuniors from '../UXCFeatureImg_UXJuniors.png';
import UXCImgFeatureUserPulse from '../UXCFeatureImg_UXProdUserPulse.png';
import UXCImgUXLeadership from '../Images/uxi-site-home-feature-ux.png';
import UXCImgWorkflowDesign from '../Images/uxi-site-home-feature-workflow.png';
import UXCImgHomeTraining from '../Images/uxi-site-home-feature-training.png';
import UXCImgHomeEmergingTech from '../Images/uxi-site-home-feature-emergingtech.png';
import UXCCardInnovationEngine from '../Images/uxi-home-innovationengine.png';
import UXCImgFeatureMission from '../Images/uxi-home-feature-mission.png';
import UXCImgFeatureClients from '../Images/uxi-home-feature-clients.png';
import UXCImgFeatureSpecializations from '../Images/uxi-home-feature-specializations.png';
import { Helmet } from 'react-helmet';

export default function PageHome() {
  return (
    <div>
    <Helmet>
      <title>UX Integrity Designing for What's Next</title>
      <meta name="description" content="UX Integrity are UX Professionals changing how Design is Done" />
      {/* Other meta tags */}
    </Helmet>
    <UXCMarquee primaryText="Designing for What's Next" subText="Building Future Ready User-centered Ecosystems" buttonTitle="Connect with Us" buttonPath="/contact" />
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Our Mission">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12} lg={6} className="uxc-page-home-min-card-height">
                        <img src={UXCImgFeatureMission} width="100%" height="auto" />
                    </Grid>
                    <Grid item xs={12} lg={6} className="uxc-page-home-min-card-height">
                        <h3 className="uxc-align-left">Ever-evolving and User-focused</h3>
                        <div className="uxc-align-left uxc-font-body">The mission of UX Integrity is to put the user first through our practice, training, and product innovation. <br /><br /><h3>Our Purpose</h3>UX Integrity has an integrated “business as mission” initiative, Innovation Engine, that aims to improve lives through innovating and providing digital transformation to non-profit organizations.</div>
                    </Grid>
                </Grid>
            </Box>
    </UXCContentBlock>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Our Services">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3} className="uxc-page-home-min-card-height">
                        <UXCCard cardHeading="UX Leadership" cardImage={UXCImgUXLeadership} shortDescription="Focusing on keeping the user in the center, our UX leadership helps solve critical design challenges in large programs." buttonTitle="Learn More" buttonPath="ux-leadership" />
                    </Grid>
                    <Grid item xs={12} lg={3} className="uxc-page-home-min-card-height">
                        <UXCCard className="uxc-page-home-min-card-height" cardHeading="Low Code Design" cardImage={UXCImgWorkflowDesign} shortDescription="Understanding where the user fits into complex business processes is a unique challenge.  Designing how to keep the user first when transforming those processes is our specialty." buttonTitle="Learn More" buttonPath="low-code-design" />
                    </Grid>
                    <Grid item xs={12} lg={3} className="uxc-page-home-min-card-height">
                        <UXCCard className="uxc-page-home-min-card-height" cardHeading="Innovation Engine" cardImage={UXCCardInnovationEngine} shortDescription="Innovation Engine is innovation as a service to benefit both for and non profit organizations by levaraging the innovation community's skillsets." buttonTitle="Learn More" buttonPath="innovation-engine" />
                    </Grid>
                    <Grid item xs={12} lg={3} className="uxc-page-home-min-card-height">
                        <UXCCard className="uxc-page-home-min-card-height" cardHeading="Training" cardImage={UXCImgHomeTraining} shortDescription="Training in both low code and user experience, our training is focused on providing real-life application to skills versus canned off-the-shelf instruction." buttonTitle="Learn More" buttonPath="training" />
                    </Grid>
                </Grid>
            </Box>
     </UXCContentBlock>
     <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="User-Centered Specializations">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12} lg={6} className="uxc-page-home-min-card-height">
                        <img src={UXCImgFeatureSpecializations} width="100%" height="auto" />
                    </Grid>
                    <Grid item xs={12} lg={6} className="uxc-page-home-min-card-height">
                        <div className="uxc-align-left uxc-font-body">
                        Our experience in enterprise and government programs has accumulated key specializations that create unique values for our clients:
                            <ul className="uxc-align-left uxc-font-body">
                            <li>Design in Low Code</li>
                            <li>Accessibility</li>
                            <li>UX Program Management</li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Box>
    </UXCContentBlock>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Client History">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12} lg={6} className="uxc-page-home-min-card-height">
                        <div className="uxc-align-left uxc-font-body">UX Integrity's team and network has experience in both commercial and government spaces, providing unique skillsets to add to your program in UX, low code, accessibility, and leadership. </div>
                    </Grid>
                    <Grid item xs={12} lg={6} className="uxc-page-home-min-card-height">
                        <img src={UXCImgFeatureClients} width="100%" height="auto" />
                    </Grid>
                </Grid>
            </Box>
    </UXCContentBlock>
  </div>
  );
}