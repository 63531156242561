import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import UXILogo from '../../uxilogo.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ExploreIcon from '@mui/icons-material/Explore';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LightBulbIcon from '@mui/icons-material/Lightbulb';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import './UXCHeaderNew.css';

export default function UXCHeaderNew() {

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const handleMobileMenuVisibility = () => {
    console.log('test visibility function');
    if(mobileMenuVisible) {
      setMobileMenuVisible(false);
    } else {
      setMobileMenuVisible(true);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="uxc-comp-header-outer">
        <Toolbar>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            className="uxc-comp-header-menu-button" onClick={handleMobileMenuVisibility}>
            <MenuIcon />
          </IconButton>
          <a href="/"><img src={UXILogo} /></a>
          <div style={{ justifyContent: 'flex-end', width: '100%', textAlign: 'right' }} className="uxc-comp-header-desktop">
          <a href="/ux-leadership"><Button color="inherit" className="uxc-comp-header-button">UX Leadership</Button></a>
          <a href="/low-code-design"><Button color="inherit" className="uxc-comp-header-button">Low Code Design</Button></a>
          <a href="/innovation-engine"><Button color="inherit" className="uxc-comp-header-button">Innovation Engine</Button></a>
          <a href="/training"><Button color="inherit" className="uxc-comp-header-button">Training</Button></a>
          <a href="/our-vision"><Button color="inherit" className="uxc-comp-header-button">Our Vision</Button></a>
          <a href="/contact"><Button color="inherit" className="uxc-comp-header-button">Contact</Button></a>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenuVisible ?
      <div className="uxc-comp-header-mobile-menu-wrap">
        <List>
          <ListItem disablePadding>
            <ListItemButton component="a" href="/ux-leadership">
              <ListItemIcon>
                <LeaderboardIcon />
              </ListItemIcon>
              <ListItemText primary="UX Leadership" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="/low-code-design">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Low Code Design" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="/innovation-engine">
              <ListItemIcon>
                <LightBulbIcon />
              </ListItemIcon>
              <ListItemText primary="Innovation Engine" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="/training">
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Training" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="/our-vision">
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary="Our Vision" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="/contact">
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="Contact" />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      : <div></div>} 
    </Box>
  );
}