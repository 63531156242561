import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    // Your Firebase project configuration
    apiKey: "AIzaSyApuQveH7_BW4IIi-MqmQV0kt0W_N4NseM",
    authDomain: "user-continuity.firebaseapp.com",
    projectId: "user-continuity",
    storageBucket: "user-continuity.appspot.com",
    messagingSenderId: "94272401177",
    appId: "1:94272401177:web:c3381920f5dfdb6cd11f88"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;