import * as React from 'react';
import './PageUXTraining.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardNoCannedTrainings from '../Images/UXCCard-NoCannedTrainings.png';
import UXCCardHyperFocused from '../Images/UXCCard-HyperFocused.png';
import UXCCardContinuousMentorship from '../Images/UXCCard-ContinuousMentorship.png';
import UXCImgLiveVirtual from '../Images/UXCFeatureImg_LiveVirtualTraining.png';
import UXCImgMonthlyMentorship from '../Images/UXCFeatureImg_MonthlyMentorship.png';
import { Button } from '@mui/material';

export default function PageUXTraining() {
  return (
    <div>
    <UXCMarquee primaryText="Train on Enterprise UX, Land the Right Role" subText="Train for the Role You Want Instead of Learning Generic UX Skills" buttonTitle="Browse Courses and Times" buttonPath="/ux-training-courses" />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="How UX Integrity is Different">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="No Canned Trainings" cardImage={UXCCardNoCannedTrainings} shortDescription="Our trainings are custom designed and tailored to a direct outcome for professional growth or corporate goal, rather than cookie-cutter templates." buttonTitle="Browse Courses and Times" buttonPath="ux-training-courses" />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Hyper-focused on Growth" cardImage={UXCCardHyperFocused} shortDescription="Our number one focus is on growth for our UX community.  Rather than one-off trainings with limited effectiveness, we seek ongoing mentorship." buttonTitle="Apply to Join a Project" buttonPath="/apply-project" />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Continuous Mentorship" cardImage={UXCCardContinuousMentorship} shortDescription="We build on our training with mentorship and work alongside our UX community to incrementally work toward their goals with hands-on guidance." buttonTitle="View Mentorship Plans" buttonPath="ux-mentorship-plans" />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Training Options">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={3}>
                        <img width="100%" height="auto" src={UXCImgLiveVirtual} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <h3 className="uxc-global-utility-text-left">Live Virtual Trainings</h3>
                        <div className="uxc-global-utility-text-left">All of our training is virtual but interactive session based training that is custom designed for a specific growth outcome.  Our aim is to design full courses or targeted workshops that are not just informational, but contribute to professional growth by sharing industry insights only learned in the field.</div>
                        <br />
                        <div className="uxc-global-utility-text-left"><a href="/ux-training-courses"><Button className="uxc-component-button-secondary" variant="outlined">Browse Courses and Times</Button></a></div>
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Mentorship Options">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={3}>
                        <img width="100%" height="auto" src={UXCImgMonthlyMentorship} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <h3 className="uxc-global-utility-text-left uxc-typo-color-white">Monthly Mentorship</h3>
                        <div className="uxc-global-utility-text-left uxc-typo-color-white">Our monthly mentorship program is designed to either build on training you attended or be tailored to your specific situation and be hands-on practical guidance.  This mentorship allows you to have a sounding board in the field to help you acheive your goals.</div>
                        <br />
                        <div className="uxc-global-utility-text-left uxc-typo-color-white"><a href="/ux-mentorship-plans"><Button className="uxc-component-button-secondary" variant="outlined">View Mentorship Plans</Button></a></div>
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}