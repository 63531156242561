// All Functions Reused
import firebase from '../firebase';

// Dependencies
const firestore = firebase.firestore();

// Add to Training Roster 
export const addToTrainingRoster = async (firstName, lastName, courseId) => {
  
    try {
      // Add the record to the "training-roster" collection
      await firestore.collection("training-roster").add({
        firstName,
        lastName,
        courseId,
      });

      console.log("Record added successfully!");
    } catch (error) {
      console.error("Error adding record: ", error);
    }
  };

  // Add to Mentor Roster
  export const addToMentorRoster = async (firstName, lastName, mentorshipPlan) => {
  
    try {
      // Add the record to the "mentor-roster" collection
      await firestore.collection("mentor-roster").add({
        firstName,
        lastName,
        mentorshipPlan,
      });

      console.log("Record added successfully!");
    } catch (error) {
      console.error("Error adding record: ", error);
    }
  };

    // Add to Mentor Roster
    export const addToProjectRoster = async (firstName, lastName, phone, email, projectTypeInternship, projectTypeCommercial, projectTypeNonProfit) => {
  
      try {
        // Add the record to the "mentor-roster" collection
        await firestore.collection("project-roster").add({
          firstName,
          lastName,
          phone,
          email,
          projectTypeInternship,
          projectTypeCommercial,
          projectTypeNonProfit
        });
  
        console.log("Record added successfully!");
      } catch (error) {
        console.error("Error adding record: ", error);
      }
    };