import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageApplyProject.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import firebase from '../firebase';
import { addToProjectRoster } from '../UXCUtilities/uxcutilities-all';

export default function PageApplyProject() {

  const [isInternshipChecked, setIsInternshipChecked] = useState(false);
  const [isJuniorCommercialChecked, setIsJuniorCommercialChecked] = useState(false);
  const [isNonProfitChecked, setIsNonProfitChecked] = useState(false);

  const [submissionForm, setSubmissionForm] = useState([]);

  const { projectRequestId } = useParams();

  console.log('Project Request Id: ' + projectRequestId);
  let projectRequestTypeId = projectRequestId;

  // Initialize shouldCheckInternship with a default value (e.g., false)
  let shouldCheckInternship = false;

  if (projectRequestTypeId === "internship") {
    shouldCheckInternship = true;
  }

  let shouldCheckJuniorCommercial = false;

  if (projectRequestTypeId === "commercial") {
    shouldCheckJuniorCommercial = true;
  }

  let shouldCheckNonprofit = false;

  if (projectRequestTypeId === "nonprofit") {
    shouldCheckNonprofit = true;
  }

  // Use the useEffect hook to set the initial checkbox state
  useEffect(() => {
    setIsInternshipChecked(shouldCheckInternship);
    setIsJuniorCommercialChecked(shouldCheckJuniorCommercial);
    setIsNonProfitChecked(shouldCheckNonprofit);
  }, [shouldCheckInternship, shouldCheckJuniorCommercial, shouldCheckNonprofit]);

    const [emailData, setEmailData] = useState({
      fromEmail: '',
      subject: '',
      firstName: '',
      lastName: '',
      phone: '',
      text: '',
      course: ''
    });

    const firestore = firebase.firestore();
  
    const sendEmailTwo = async () => {

          // Access checkbox values
      const interestedInInternship = isInternshipChecked;
      const interestedInJuniorCommercial = isJuniorCommercialChecked;
      const interestedInNonProfit = isNonProfitChecked;

      try {
        const response = await axios.post('https://us-central1-user-continuity.cloudfunctions.net/sendRegistrationEmailMod', {
          subject: 'Mentor Email Confirmation ' + emailData.firstName + " " + emailData.lastName,
          message: `
    <html>
      <body>
        <p>Confirmed Project Application Registration</p>
        <p>First Name: ${emailData.firstName}</p>
        <p>Last Name: ${emailData.lastName}</p>
        <p>Phone: ${emailData.phone}</p>
        <p>Email: ${emailData.fromEmail}</p>
        <p>Project Type Selections:</p>
        <p>Internship: ${interestedInInternship}</p>
        <p>Commercial: ${interestedInJuniorCommercial}</p>
        <p>Nonprofit: ${interestedInNonProfit}</p>
      </body>
    </html>
  `,
          phone: emailData.phone
        });
    
        console.log('Email sent 1:', response.data);

        addToProjectRoster(emailData.firstName, emailData.lastName, emailData.phone, emailData.fromEmail, interestedInInternship, interestedInJuniorCommercial, interestedInNonProfit);
        setSubmissionForm(true);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmailData({ ...emailData, [name]: value });
    };

    


  return (
    <UXCPageTemplateUtilityScreen headingOne="Project Participation Application">
      {submissionForm == true ? 
        <div>Submission Complete</div>
      :
      <form>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <h2>Next Steps</h2>
            <div className="uxc-page-apply-instructions">
            Fill out this form and we'll add you to our project roster and reach out to you directly to discuss your background.
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="firstName" label="First Name" variant="outlined" value={emailData.firstName} onChange={handleInputChange} />
          </Grid>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="lastName" label="Last Name" variant="outlined" value={emailData.lastName} onChange={handleInputChange} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="fromEmail" label="Email" variant="outlined" value={emailData.fromEmail} onChange={handleInputChange} />
          </Grid>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="phone" label="Phone" variant="outlined" value={emailData.phone} onChange={handleInputChange} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} className="uxc-page-apply-project-checklist-wrap">
            <div><FormControlLabel
              control={<Checkbox
                checked={isInternshipChecked}
                onChange={(event) => setIsInternshipChecked(event.target.checked)}
              />}
              label="Internship (Unpaid, Experience Forming)"
            /></div>

            <div><FormControlLabel
              control={<Checkbox
                checked={isJuniorCommercialChecked}
                onChange={(event) => setIsJuniorCommercialChecked(event.target.checked)}
              />}
              label="Junior Commercial"
            /></div>

            <div><FormControlLabel
              control={<Checkbox
                checked={isNonProfitChecked}
                onChange={(event) => setIsNonProfitChecked(event.target.checked)}
              />}
              label="Non-profit"
            /></div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <Button variant="outlined" onClick={sendEmailTwo}>Register</Button>
          </Grid>
        </Grid>
      </form>
      }
    </UXCPageTemplateUtilityScreen>
  );
}