import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import './PageRegistrationFlowStep1.css';
import { useNavigate } from 'react-router';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';

const PageRegistrationFlowStep1 = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [alert, setAlert] = useState(null);
  const [uxcDocumentID, setDocumentID] = useState('');
  const [uxcCustomerID, setUXCCustomerID] = useState('');
  const firestore = firebase.firestore();
  const collectionRef = firestore.collection('user-profile');
  //console.dir(collectionRef);

  const [stripeApiKey, setStripeApiKey] = useState('');

  useEffect(() => {
    // Make the API request to your Google Cloud Function
    axios.get('https://us-central1-user-continuity-392321.cloudfunctions.net/UCSKeyServer')
      .then((response) => {
        setStripeApiKey(response.data.stripeApiKey);
      })
      .catch((error) => {
        console.error('Error fetching Stripe API key:', error);
      });
  }, []);

  const handleCreateCustomer = async () => {
    try {
      const response = await axios.post(
        'https://api.stripe.com/v1/customers',
        {
          email: email, // Replace with the customer's email
        },
        {
          headers: {
            'Authorization': `Bearer ${stripeApiKey}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
  
      // Handle the customer creation response
      console.log('Customer created:', response.data);
      console.log('Customer ID: ' + response.data.id);
      updateUserProfile(email, response.data.id);

    } catch (error) {
      console.error('Error creating customer:', error);
      console.error('Error creating customer:', error.response?.data?.error || error.message);
    }
  };

  // Create a data object representing the new record
  const newUserProfile = {
    email: email,
    firstName: firstName,
    lastName: lastName
    // ... other fields
  };

  

  const handleRegistration = async () => {
    try {

      await firebase.auth().createUserWithEmailAndPassword(email, password);
      handleCreateCustomer();

      console.log('Registration successful!');
      setAlert({ type: 'success', message: 'Registration successful!' });
        // Add the new record to the collection
        collectionRef.add(newUserProfile)
        .then(docRef => {
          console.log('Document added with ID:', docRef.id);
        })
        .catch(error => {
          console.error('Error adding document:', error);
        });
    } catch (error) {
      console.error('Error during registration:', error.message);
      setAlert({ type: 'error', message: `Error during registration: ${error.message}` });
    }
  };

  const updateUserProfile = async (email, stripeCustomerID) => {
    try {
      const userProfileRef = firestore.collection('user-profile');
      const querySnapshot = await userProfileRef.where('email', '==', email).get();

      querySnapshot.forEach((doc) => {
        // Update the document with the new stripeCustomerID field
        userProfileRef.doc(doc.id).update({ stripeCustomerID });
      });
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  return (
    <UXCPageTemplateUtilityScreen headingOne="Register Now">
      <h2>Provide an Email/Username and Password</h2>
      {alert ? (
        <div className={`alert ${alert.type}`}>
          <p>{alert.message}</p>
          {alert.type === 'success' && (
            <p>
              Thanks for Joining! <a href="/profile">View your profile.</a>.
            </p>
          )}
        </div>
      ) : (
        <>
          <div>
          <TextField id="outlined-basic" label="Email" variant="outlined" InputLabelProps={{ shrink: true }} type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
          <TextField id="outlined-basic" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div>
          <TextField id="outlined-basic" label="First Name" variant="outlined" InputLabelProps={{ shrink: true }} type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)} />
          </div>
          <div>
          <TextField id="outlined-basic" label="Last Name" variant="outlined" InputLabelProps={{ shrink: true }} type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)} />
          </div>
          <div>
            <Button className="uxc-component-button-primary" color="inherit" type="submit" variant="contained" onClick={handleRegistration}>Register</Button>
          </div>
          <p>
              Already have an account? <a href="/login">Login here</a>.
            </p>
        </>
      )}
    </UXCPageTemplateUtilityScreen>
  );
};

export default PageRegistrationFlowStep1;