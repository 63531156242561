import * as React from 'react';
import './PageOurVision.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCFeatureLargeVision from '../Images/UXCFeatureLarge-Vision.png';
import UXCFeatureLargeVisionCommunity from '../Images/UXCFeatureLarge-Vision-Community.png';
import UXCCardVisionTraining from '../Images/UXCCard-Vision-Training.png';
import UXCCardVisionMentoring from '../Images/UXCCard-Vision-Mentoring.png';
import UXCCardVisionServantLeadership from '../Images/UXCCard-Vision-ServantLeadership.png';
import { Helmet } from 'react-helmet';

export default function PageOurVision() {
  return (
    <div>
    <Helmet>
      <title>UX Integrity's Vision</title>
      <meta name="description" content="UX Integrity is focused improving the culture of innovation." />
      {/* Other meta tags */}
    </Helmet>
    <UXCMarquee primaryText="Setting the Stage for Others to Innovate is Our Goal" subText="Innovation is Our Day Job, Lifting Communities is What's Next"/>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Design and Innovation to Bring Change for Good">
            <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={6}>
                        <img src={UXCFeatureLargeVision} width="100%" height="auto" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <h3 className="uxc-global-utility-text-left uxc-typo-color-white">Innovate and Grow</h3>
                        <div className="uxc-global-utility-text-left uxc-typo-color-white uxc-font-body"><p>We see many of the world's problems as something design and innovation can contribute to improving or even resolving.  In this effort, we see the need to develop strong programs that can help build sources for innovation that connect across industries and disciplines.  While doing this, our focus to keep the user at the center means iterative adoption of newly innovative contributions will make an impact. </p></div>
                    </Grid>
            </Grid>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="We Bring Innovation to the Community">
            <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={6}>
                        <img src={UXCFeatureLargeVisionCommunity} width="100%" height="auto" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <h3 className="uxc-global-utility-text-left">Using Technology to Help Others</h3>
                        <div className="uxc-global-utility-text-left uxc-font-body">UX Integrity is connected to non-profit organizations with the intent of driving change through technology and innovation in local and global communities.</div>
                    </Grid>
            </Grid>
        </UXCContentBlock>
  </div>
  );
}