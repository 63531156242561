import * as React from 'react';
import { useEffect, useState } from 'react';
import './PageApplyMentor.css';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import UXCPageTemplateUtilityScreen from '../Components/UXCPageTemplateUtilityScreen/UXCPageTemplateUtilityScreen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import firebase from '../firebase';
import { addToMentorRoster } from '../UXCUtilities/uxcutilities-all';

export default function PageApplyMentor() {

  const [submissionForm, setSubmissionForm] = useState([]);

  const { mentorPlanId } = useParams();

  console.log('Mentor Plan Id: ' + mentorPlanId);
  let mentorApplicationPlanId = mentorPlanId;

    const [emailData, setEmailData] = useState({
      fromEmail: '',
      subject: '',
      firstName: '',
      lastName: '',
      phone: '',
      text: '',
      course: ''
    });

    const firestore = firebase.firestore();
  
    const sendEmailTwo = async () => {
      try {
        const response = await axios.post('https://us-central1-user-continuity.cloudfunctions.net/sendRegistrationEmailMod', {
          subject: 'Mentor Email Confirmation ' + emailData.firstName + " " + emailData.lastName,
          message: `
    <html>
      <body>
        <p>Confirmed Mentor Registration for: ${mentorApplicationPlanId}</p>
        <p>First Name: ${emailData.firstName}</p>
        <p>Last Name: ${emailData.lastName}</p>
        <p>Phone: ${emailData.phone}</p>
        <p>Email: ${emailData.fromEmail}</p>
      </body>
    </html>
  `,
          phone: emailData.phone
        });
    
        console.log('Email sent 1:', response.data);
        addToMentorRoster(emailData.firstName, emailData.lastName, mentorApplicationPlanId);
        setSubmissionForm(true);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmailData({ ...emailData, [name]: value });
    };


  return (
    <UXCPageTemplateUtilityScreen headingOne="Mentorship Registration">
      {submissionForm == true ? 
        <div>Submission Complete</div>
      :
      <form>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <h2>Next Steps</h2>
            <div className="uxc-page-apply-instructions">
            Fill out this form and we'll reach out to you and connect you with a UX mentor that best matches you.
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="firstName" label="First Name" variant="outlined" value={emailData.firstName} onChange={handleInputChange} />
          </Grid>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="lastName" label="Last Name" variant="outlined" value={emailData.lastName} onChange={handleInputChange} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="fromEmail" label="Email" variant="outlined" value={emailData.fromEmail} onChange={handleInputChange} />
          </Grid>
          <Grid item lg={6}>
            <TextField fullWidth id="outlined-basic" name="phone" label="Phone" variant="outlined" value={emailData.phone} onChange={handleInputChange} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="uxc-global-utility-text-left">
          <Grid item lg={12}>
            <Button variant="outlined" onClick={sendEmailTwo}>Register</Button>
          </Grid>
        </Grid>
      </form>
      }
    </UXCPageTemplateUtilityScreen>
  );
}