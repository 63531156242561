import * as React from 'react';
import './PageAbout.css';
import Box from '@mui/material/Box';

export default function PageAbout() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <div>Test About</div>
    </Box>
  );
}