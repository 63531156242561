import * as React from 'react';
import './PageInnovationEngine.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';
import UXCCardForInnovators from '../Images/uxi-innovation-card-forinnovators.png';
import UXCCardForCompanies from '../Images/uxi-innovation-card-forcompanies.png';
import UXCCardForNonprofits from '../Images/uxi-innovation-card-fornonprofits.png';
import UXCMarketingTrainingListPegaUXItem from '../Images/Marketing/uxi-site-training-list-pega-ux.png';
import { Button } from '@mui/material';
import UXCImgSubFeatureCrowdSourcing from '../Images/uxi-innovation-subfeature-crowdsourcing.png';
import UXCImgSubFeatureCompanyGaps from '../Images/uxi-innovation-subfeature-companygaps.png';
import UXCImgSubFeatureNonprofit from '../Images/uxi-innovation-subfeature-nonprofit.png';
import { Helmet } from 'react-helmet';

export default function PageInnovationEngine() {
  return (
    <div>
    <Helmet>
      <title>Innovation Engine is a Business as a Mission by UX Integrity</title>
      <meta name="description" content="UX Integrity features a Business as Mission that helps reach innovation to nonprofits and businesses around the world." />
      {/* Other meta tags */}
    </Helmet>
    <UXCMarquee primaryText="Connecting Innovators, Accelerating Change" subText="A “Business as Mission” That Seeks to Grow the Impact and the Skills of Innovators Around the World."/>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white">
            <Box sx={{ flexGrow: 1 }}>
                <h2>The Innovation Engine Initiative is Launching April 2024</h2>
            </Box>
        </UXCContentBlock>
    <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Connecting Innovators with Impact Areas">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} className="uxc-page-innovation-engine-min-card-height">
                        <UXCCard cardHeading="For Innovators" cardImage={UXCCardForInnovators} shortDescription="Innovators on this platform can connect with each other, train, and find new work in an agency approach." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-innovation-engine-min-card-height">
                        <UXCCard cardHeading="For Companies" cardImage={UXCCardForCompanies} shortDescription="Companies can crowd-source innovation to extend their design teams to fill in gaps in their design ecosystem, resulting in stronger products." />
                    </Grid>
                    <Grid item xs={12} lg={4} className="uxc-page-innovation-engine-min-card-height">
                        <UXCCard cardHeading="For Non-profits" cardImage={UXCCardForNonprofits} shortDescription="Non-profits can find traditional design work at discounted rates or crowd-source design under our agency roof to realize their organization’s vision." />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="How Innovation Engine Works">
          <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={3}>
                        <img width="100%" height="auto" src={UXCImgSubFeatureCrowdSourcing} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <h3 className="uxc-global-utility-text-left">Crowd Sourced Innovation, Curated by Project Managers</h3>
                        <div className="uxc-global-utility-text-left uxc-font-body">Innovation Engine works by connecting innovation professionals around the world to opportunities that can bring them new work.  We build project teams utilizing experienced leaders to drive projects at a lower cost and higher impact.</div>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={3}>
                        <img width="100%" height="auto" src={UXCImgSubFeatureCompanyGaps} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <h3 className="uxc-global-utility-text-left">Filling the Gap in Innovation for Companies</h3>
                        <div className="uxc-global-utility-text-left uxc-font-body">As companies are faced with constant industry changes, innovation requires more support and a different system.  By extending the existing professionals a company already has and giving them tools to be successful, companies can innovate faster with less cost and greater outcomes.</div>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="uxc-global-margin-bottom-4x">
                    <Grid item xs={12} lg={3}>
                        <img width="100%" height="auto" src={UXCImgSubFeatureNonprofit} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <h3 className="uxc-global-utility-text-left">A Step Up for Non-profits</h3>
                        <div className="uxc-global-utility-text-left uxc-font-body">Innovation Engine is a “Business as Mission” meaning we seek to utilize the skill of innovation to help Non-profits around the world be better at what they do for a discounted cost or complimentary.  By lessening the load on great organizations, we can extend their impact and increase their outcome.</div>
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
  </div>
  );
}